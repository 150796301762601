/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $nav_wrap = ('.gn-wrap'),
            $nav_trigger = $('.gn--trigger'),
            $burger = $('.burger'),
            $global_nav = $('.gn'),
            $nav_top_level = $('.gn--items'),
            $nav_top_level_item   = $('.gn--items > .menu-item'),
            $nav_mobile_search   = $('.mobile-search'),
            $nav_item_with_subnav = $('.gn--items > .menu-item-has-children'),
            $sub_nav_trigger    = $('<div class="sub-trigger-wrap d-xl-none"><div class="subnav-icon"><span></span></div></div>'),
            $submenu = $('.sub-menu'),
            $submenu_link = $('.sub-menu li a'),
            $active_sub_nav = $('li.active .sub-menu .active'),
            $ul = $('ul'),
            $searchTrigger = $('.search-icon'),
            $searchForm = $('.global-search'),
            windowWidth = $('body').width(),
            targetElement = document.querySelector('.gn');

        /*
        |----------------------------------------------------------------
        |  Global Naviagation
        |----------------------------------------------------------------
        */

        $($sub_nav_trigger).appendTo($nav_item_with_subnav);


        function gnOpen() {
            var windowWidth = $(window).width();
            $($nav_wrap).addClass('expand-wrap');
            $($burger).addClass('burger--animate');
            $($global_nav).addClass('gn--open');
            $($nav_top_level_item).addClass('show-item');
            $($nav_mobile_search).addClass('show-item');
            $($active_sub_nav).parents($nav_item_with_subnav).addClass('slide-down-sub-nav');

            if ( ( $($active_sub_nav).length > 0) ) {

                if(windowWidth < 768) {
                  $($active_sub_nav).parent($submenu).addClass('show-sub-nav').slideDown(0);
                } else {
                  $($active_sub_nav).parent($submenu).addClass('show-sub-nav').delay(250).fadeIn();
                }

                $($nav_top_level).addClass('move-off-gn');
                $($active_sub_nav).parent($ul).addClass('move-on-subnav').show();
            }

            bodyScrollLock.disableBodyScroll(targetElement);
        }

        function gnClose() {
            $($nav_wrap).removeClass('expand-wrap');

            if( !$($nav_top_level).hasClass('move-off-gn') ) {
                $($nav_top_level_item).removeClass('show-item');
                $($nav_mobile_search).removeClass('show-item');
                $($burger).removeClass('burger--animate');
                $($global_nav).removeClass('gn--open');
            } else {
                $($submenu).removeClass('show-sub-nav').fadeOut(300);
                $($burger).removeClass('burger--animate');
                $($global_nav).removeClass('gn--open');
                $($nav_top_level).removeClass('move-off-gn');
                $($nav_top_level_item).removeClass('show-item');
                $($nav_mobile_search).removeClass('show-item');
            }
            bodyScrollLock.enableBodyScroll(targetElement);
        }


        function subNav() {
            windowWidth = $(window).width();

            $('.gn li .sub-trigger-wrap').unbind("click").on('click', function(event) {
                event.preventDefault();
                if ($(this).hasClass('slide-down-sub-nav'))  {
                    $(this).removeClass('slide-down-sub-nav');
                    $(this).siblings('.sub-menu').slideUp(450);
                } else {
                    event.stopPropagation();
                    $('.gn li .sub-trigger-wrap').removeClass('slide-down-sub-nav').find('.sub-menu').slideUp();
                    $(this).addClass('slide-down-sub-nav');
                    $(this).siblings('.sub-menu').addClass('show-sub-nav').slideDown();
                }
            });
        }

        $($submenu_link).on('click', function(event) {
          event.stopPropagation();
        });

        function globalNav() {
            $($nav_trigger).on('click', function(event) {
            $(this).toggleClass('burger-active');
            event.preventDefault();

            if($($global_nav).hasClass('gn--open')) {
                gnClose();
                $($nav_item_with_subnav).removeClass('slide-down-sub-nav').find('.sub-menu').slideUp(400);
            } else {
                gnOpen();
            }
            });
        }

        /*
        |----------------------------------------------------------------
        |  Local Navigation
        |----------------------------------------------------------------
        */
        $('.mobile-cat-nav').on('click', function(e) {
          e.preventDefault();
          $(this).next('nav').slideToggle();
        });

        /*
        |----------------------------------------------------------------
        |  Global Search
        |----------------------------------------------------------------
        */
        $($searchTrigger).on('click', function(e) {
          console.log('clickc');
          e.preventDefault();
          $($searchForm).toggleClass('is-showing');
        });

        /*
        |----------------------------------------------------------------
        |  Blog Categories
        |----------------------------------------------------------------
        */
        if($('.blog-cat').length) {

          $('.env-trigger').on('click', function(e) {
            e.preventDefault();
            $(this).next('ul').slideToggle();
            $(this).find('span').toggleClass('active');
          });

          $('.sector-trigger').on('click', function(e) {
            e.preventDefault();
            $(this).next('ul').slideToggle();
            $(this).find('span').toggleClass('active');
          });
        }

        /*
        |----------------------------------------------------------------
        |  Flickity
        |----------------------------------------------------------------
        */
        function contentSlider($slider, $dots, $button, $height, $duration) {
          $($slider).flickity({
              wrapAround: false,
              draggable: true,
              pageDots: $dots,
              autoPlay: $duration,
              prevNextButtons: $button,
              adaptiveHeight: $height,
              imagesLoaded: true
          });
        }

        contentSlider('.home-slider', false, true, false, 3000);
        contentSlider('.matrix-img-slider', false, true, false, 5000);


        globalNav();
        subNav();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        window.addEventListener('load',function(){
            $.fn.matchHeight._update();
            $('.theme-wrap').addClass('show-theme');
        })
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
